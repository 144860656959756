<template>
  <page title="收货地址" :show-dialog="showNew" @mask-click="showNew = false">
    <div slot="header-after" class="px-4">
      <icon color="gray-800" icon="icon-add-select" size="24" @click="newAddress"></icon>
    </div>
    <card class="mb-3 p-4 flex" v-for="(item,index) in 5" :key="item">
      <div class="flex-1 overflow-hidden">
        <div class="mb-2">
          <span class="text-gray-dark font-medium">王刚</span>
          <span class="text-gray-light text-sm ml-3">18838383838</span>
        </div>
        <div class="text-sm leading-5 text-gray-600">陕西省西安市未央区 未央新城13栋1单元1202</div>
      </div>
      <div class="flex flex-col justify-between items-end ml-3">
        <tag color="green" v-if="index == 0">默认地址</tag>
        <icon color="red-500" icon="icon-ashbin" size="24" @click="del(item)"></icon>
      </div>
    </card>

    <!-- 弹出层 -->
    <div slot="dialog" >
      <div class="p-4">
        <div class="text-center text-gray-dark font-medium mb-4">新增地址</div>
        <cube-input v-model="value1" placeholder="收件人" class="mb-3"></cube-input>
        <cube-input v-model="value2" placeholder="联系电话" class="mb-3"></cube-input>
        <cube-input v-model="value3" placeholder="详细地址" class="mb-3"></cube-input>

        <div class="flex items-center mt-4">
          <div class="flex-1 text-sm">是否默认</div>
          <cube-switch v-model="isDefault" />
        </div>
      </div>
      <btn radius="none" text-size="base" block @click="submit">保存</btn>
    </div>
  </page>
</template>

<script>
export default {
  data(){
    return{
      showNew: false, 
      value1:'',
      value2:'',
      value3:'',
      isDefault: false,
    }
  },
  methods:{
    del(e) {
      console.log('删除',e);
      this.showAlert(e)
    },
    showAlert(e) {
      this.dialog = this.$createDialog({
        type: 'confirm',
        title: '警告',
        content: `确定要删除${e}？`,
        onConfirm: (e, promptValue) => {
          this.$createToast({
            type: 'warn',
            time: 1000,
            txt: `已删除`
          }).show()
        },
      }).show()
    },
    newAddress() {
      console.log('添加');
      this.showNew = true;
    },
    submit() {
      console.log('提交');
      this.showNew = false;
    }
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>